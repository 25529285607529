<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="관리대상군 부서"
          :columns="grid1.columns"
          :data="deptsFilter"
          :gridHeight="gridHeight"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="관리대상군 근로자"
          :columns="grid2.columns"
          :data="usersFilter"
          :gridHeight="gridHeight"
        >
        </c-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'jobStressAssessmentResultDeptUser',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaJobStressPlanId: '',
      }),
    },
    plan: {
      type: Object,
      default: () => ({
        heaJobStressPlanId: '',  // 직무스트레스 계획 일련번호
        plantCd: null,  // 사업장
        planYear: '',  // 년도
        jobStressPlanName: '',  // 평가계획명
        assessmentStartDate: '',  // 평가 시작일
        assessmentEndDate: '',  // 평가 종료일
        period: [], // 평가기간
        remarks: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        users: [], // 평가자
        centers: [], // 중앙값
        depts: [], // 중앙값
        imprs: [], // 중앙값
        resultTable: [],
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid1: {
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'scope',
            field: 'scope',
            label: '점수',
            child: [
              {
                name: 'physicalEnv',
                field: 'physicalEnv',
                label: '물리적환경',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'jobDemand',
                field: 'jobDemand',
                label: '직무요구',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'jobAutonomy',
                field: 'jobAutonomy',
                label: '직무자율',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'relationship',
                field: 'relationship',
                label: '관계갈등',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'jobInsecurity',
                field: 'jobInsecurity',
                label: '직무불안정',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'organizational',
                field: 'organizational',
                label: '조직체계',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'inadequate',
                field: 'inadequate',
                label: '보상부적절',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'workplace',
                field: 'workplace',
                label: '직장문화',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
            ]
          },
        ],
      },
      grid2: {
        columns: [
          {
            name: 'userName',
            field: 'userName',
            label: '평가자',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'scope',
            field: 'scope',
            label: '점수',
            child: [
              {
                name: 'physicalEnv',
                field: 'physicalEnv',
                label: '물리적환경',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'jobDemand',
                field: 'jobDemand',
                label: '직무요구',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'jobAutonomy',
                field: 'jobAutonomy',
                label: '직무자율',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'relationship',
                field: 'relationship',
                label: '관계갈등',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'jobInsecurity',
                field: 'jobInsecurity',
                label: '직무불안정',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'organizational',
                field: 'organizational',
                label: '조직체계',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'inadequate',
                field: 'inadequate',
                label: '보상부적절',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
              {
                name: 'workplace',
                field: 'workplace',
                label: '직장문화',
                style: 'width:50px',
                align: "right",
                sortable: true,
                type: 'cost',
              },
            ]
          }
        ],
      },
      editable: true,
      isSave: false,
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? String(this.contentHeight - 80) + 'px' : '500px'
    },
    deptsFilter() {
      let _items = [];
      if (this.plan.depts && this.plan.depts.length > 0) {
        _items = this.$_.filter(this.plan.depts, { manageTargetGroupFlag: 'Y' })
      }
      return _items;
    },
    usersFilter() {
      let _items = [];
      if (this.plan.users && this.plan.users.length > 0) {
        _items = this.$_.filter(this.plan.users, { manageTargetGroupFlag: 'Y' })
      }
      return _items;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>