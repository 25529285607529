var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "관리대상군 부서",
              columns: _vm.grid1.columns,
              data: _vm.deptsFilter,
              gridHeight: _vm.gridHeight,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "관리대상군 근로자",
              columns: _vm.grid2.columns,
              data: _vm.usersFilter,
              gridHeight: _vm.gridHeight,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }